import React from 'react'
import { graphql } from 'gatsby'
import { PageContextPropsType } from '../../i18n/i18n'
import LayoutLp from '../components/LayoutLp'
import SEO from '../components/SEO'
import * as styles from '../styles/Lp.css'
import Product from '../components/pages/lp/Product'
import Example from '../components/pages/lp/Example'
import { LogoList } from '../components/pages/lp/LogoList'
import { Hero } from '../components/pages/lp/Hero'
import { Usecase } from '../components/pages/lp/Usecase'
import { Issue as IssueList } from '../components/pages/lp/Issue'
import { Gain } from '../components/pages/lp/Gain'
import { LandingPageData } from '../types/lp'

type LandingPageProps = {
  data: { newtLandingPage: LandingPageData }
} & PageContextPropsType

// ABtestを流用して作成したGoogle Optimize用のLP
export default function LandingPage({ data, pageContext }: LandingPageProps) {
  const { newtLandingPage } = data
  const slug = `/lp/${newtLandingPage.slug}/`
  const meta = newtLandingPage.meta
  const TYPE = 'abtest'

  return (
    <LayoutLp type="go" pageContext={pageContext}>
      <SEO title={meta.title} description={meta.description} path={slug} ogImageUrl={meta.ogImage.src} />
      <main className={styles.Container}>
        <Hero type="go" />
        {newtLandingPage.section.map((section: any) => {
          switch (section.type) {
            case 'LOGO':
              return <LogoList data={section.data} key={section._id} />
            default:
              break
          }
        })}
        <Product />
        <Usecase type={TYPE} />
        <IssueList type={TYPE} />
        <Gain type={TYPE} />
        <Example type={TYPE} />
      </main>
    </LayoutLp>
  )
}

export const query = graphql`
  query ($_id: String!) {
    newtLandingPage(_id: { eq: $_id }) {
      _id
      pageName
      slug
      meta {
        title
        description
        ogImage {
          src
        }
      }
      section {
        ... on Hero {
          _id
          type
          data {
            heroShoulderCopy
            heroTitleCopy
            heroBackgroundImage {
              src
            }
            heroButtonLabel1
            heroButtonUrl1
            heroLinkLabel1
            heroLinkUrl1
            heroButtonLabel2
            heroButtonUrl2
            heroLinkLabel2
            heroLinkUrl2
          }
        }
        ... on Logo {
          _id
          type
          data {
            logoImage {
              _id
              src
            }
          }
        }
        ... on Head {
          _id
          type
          data {
            headText
          }
        }
        ... on Appeal3Column {
          _id
          type
          data {
            appeal3TitleCopy
            appeal3Image1 {
              src
            }
            appeal3Text1
            appeal3Image2 {
              src
            }
            appeal3Text2
            appeal3Image3 {
              src
            }
            appeal3Text3
          }
        }
        ... on Appeal2Column {
          _id
          type
          data {
            appeal2TitleCopy
            appeal2Image1 {
              src
            }
            appeal2Text1
            appeal2Image2 {
              src
            }
            appeal2Text2
          }
        }
        ... on Appeal1Column {
          _id
          type
          data {
            appeal1TitleCopy
            appeal1Text
            appeal1ImagePc {
              src
            }
            appeal1ImageSp {
              src
            }
            appeal1ButtonUrl
            appeal1ButtonLabel
          }
        }
        ... on Usecase {
          _id
          type
          data {
            usecaseContents {
              _id
              usecaseContentTitleCopy
              usecaseContentBody
              usecaseContentImage {
                src
              }
            }
            usecaseButtonLabel
            usecaseButtonUrl
          }
        }
        ... on Voice {
          _id
          type
          data {
            voiceContents {
              _id
              voiceContentImage {
                src
              }
              voiceContentName
              voiceContentBody
            }
            voiceButtonLabel
            voiceButtonUrl
          }
        }
        ... on Faq {
          _id
          type
          data {
            faqContents {
              _id
              faqContentQuestion
              faqContentAnswer
            }
          }
        }
        ... on Issue {
          _id
          type
          data {
            issueTitleCopy
            issueTitleCopy1
            issueText1
            issueTitleCopy2
            issueText2
            issueTitleCopy3
            issueText3
          }
        }
        ... on Appeal2Column2Row {
          _id
          type
          data {
            appeal2Column2TitleCopy
            appeal2Column2Image1 {
              src
            }
            appeal2Column2Text1
            appeal2Column2Image2 {
              src
            }
            appeal2Column2Text2
            appeal2Column2Image3 {
              src
            }
            appeal2Column2Text3
            appeal2Column2Image4 {
              src
            }
            appeal2Column2Text4
          }
        }
      }
    }
  }
`
